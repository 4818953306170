export const AppRoute = {
	Root: '/',
	UILibrary: 'uilibrary',
	Competitions: 'competitions',
	Competition: ':competitionId',
	Rating: 'rating',
	SignUp: 'signup',
	SignIn: 'signin',
	EditProfile: 'edit-profile',
	ForgotPassword: 'forgot-password',
	ChangePassword: 'change-password',
	EmailConfirmation: 'email-confirmation',
	JudgeChoice: 'judge-choice',
	CreateGroup: 'create-group',
	CreatePairs: 'create-pairs',
	OrdersGroupAssign: 'orders-group-assign',
	JudgeAssign: 'judge-assign',
	JudgeCompetition: 'judge-competition',
	VerifyPayment: 'verify-payment',
	NotFound: '*'
}
